import { clsx } from 'clsx';
import { Media } from '@shared/lib/media';
import { useSidebarModal } from '../model/useSidebarModal';
import { useStickySidebarEffect } from '../model/useStickySidebar';
import styles from './sidebar-desktop.module.scss';

export function SidebarDesktop({ children }: { children: JSX.Element }) {
  const sidebarRef = useStickySidebarEffect();
  const modal = useSidebarModal();

  return (
    <Media greaterThanOrEqual="notebook">
      {(className, renderChildren) =>
        renderChildren ? (
          <aside
            itemScope
            itemType="http://schema.org/WPSideBar"
            className={clsx(styles.root, className, {
              [styles.rootActive]: modal.visible,
            })}
            ref={sidebarRef}
          >
            {children}
          </aside>
        ) : null
      }
    </Media>
  );
}
