import autobind from 'autobind-decorator';
import { Component } from 'react';
import Modal from 'react-modal';
import { modalEmitter } from 'modules/event-emitters';
import { withTranslation } from 'react-i18next';
import { Button } from '@shared/ui/button';

const customStyles = {
  overlay: {
    zIndex: 20,
    backgroundColor: 'rgba(252, 252, 252, 0.7)',
    overflowY: 'auto',
  },
  content: {
    top: '40%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 16px 60px 20px',
    overflow: 'visible',
    padding: '30px 60px 36px',
    width: '400px',
    boxSizing: 'border-box',
  },
};

class ErrorModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
    modalEmitter.addListener('Modal.Error.Open', this.openModal);
    modalEmitter.addListener('Modal.Error.Close', this.closeModal);
  }

  componentWillUnmount() {
    modalEmitter.removeListener('Modal.Error.Open', this.openModal);
    modalEmitter.removeListener('Modal.Error.Close', this.closeModal);
  }

  @autobind
  openModal() {
    this.setState({
      modalIsOpen: true,
    });
  }

  @autobind
  closeModal() {
    this.setState({
      modalIsOpen: false,
    });
  }

  render() {
    const { modalIsOpen } = this.state;
    const { t } = this.props;
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={this.closeModal}
        style={customStyles}
        contentLabel="modal-error"
      >
        <div className="modal-error">
          <h3 className="form__title">{t('ErrorModal.an error occurred')}!</h3>
          <Button onClick={() => window.location.reload()}>
            {t('ErrorModal.ok')}
          </Button>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(ErrorModal);
