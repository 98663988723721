import { deliverySliceSelectors } from '@entities/delivery';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@shared/lib/store';
import { PageTitle } from '@shared/ui/titles';
import { Media } from '@shared/lib/media';
import { useSelectCity } from '@entities/cities';
import { LinkTabs } from '@shared/ui/link-tabs';
import styles from './delivery-header.module.scss';

export function DeliveryHeader() {
  const { t } = useTranslation();
  const { city_name } = useSelectCity();

  const { deliveryData } = useAppSelector(
    deliverySliceSelectors.selectDeliveryState,
  );

  const tabsItems = [
    {
      url: `/delivery`,
      title: t('localization.Delivery'),
    },
    {
      url: `/addresses`,
      title: t('localization.Stores'),
    },
  ];

  const headerText = deliveryData
    ? t('CityAvg.To your address')
    : `${t('CityAvg.Delivery by the city')} ${city_name}`;

  return (
    <div className={styles.deliveryHeader}>
      <Media greaterThanOrEqual="notebook">
        {(className, renderChildren) =>
          renderChildren ? (
            <PageTitle className={className}>{headerText}</PageTitle>
          ) : null
        }
      </Media>
      <div className={styles.headerTabs}>
        <LinkTabs items={tabsItems} />
      </div>
    </div>
  );
}
