import { IHeaderMenu } from '@entities/header-menu';
import type { FetchLoaderType } from '@shared/lib/routing';
import { IFooterLink } from '@widgets/footer/footer-links';
import { ISocialLinks } from '@widgets/footer/social-links';

type Params = {
  city: string;
  pageId: string;
};
export const cityLayoutLoader = async (
  fetch: FetchLoaderType,
  { params, lang }: { params: Params; lang: string },
) => {
  const { city } = params;
  const footerLinksResponse = await fetch<{ footer: IFooterLink[] }>({
    url: '/setting/footer',
    params: { city, lang },
  });

  const socialResponse = await fetch<{ social: ISocialLinks }>({
    url: '/setting/social',
    params: { city },
  });

  const headerMenuResponse = await fetch<{ header_menu: IHeaderMenu[] }>({
    url: '/setting/header_menu',
    params: { city, lang },
  });

  return {
    footerLinksData: footerLinksResponse.data.footer,
    socialData: socialResponse.data.social,
    headerMenuData: headerMenuResponse.data.header_menu,
  };
};
