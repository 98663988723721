import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { modalEmitter, orderEmitter } from 'modules/event-emitters';
import ResponseError from 'modules/response/error';
import errors from 'modules/response/error-codes';

export const useReorderHandler = () => {
  const [inProcess, setInProcess] = useState(false);

  const reorderHandler = useCallback(
    (order, isNew = null) => {
      if (inProcess) return true;

      const form = {
        order_hash: order.hash,
      };

      if (isNew !== undefined && isNew !== null) {
        form.is_new = isNew;
      }

      setInProcess(true);
      axios
        .post('/order/reorder', form)
        .then(() => {
          setInProcess(false);
          window.location.href = '/cart';
        })
        .catch((error) => {
          modalEmitter.emit('Reorder.Modal.Close');
          setInProcess(false);
          const responseError = new ResponseError(error);
          if (
            responseError.isApiError() &&
            responseError.getCode() === errors.REORDER_CART_NOT_EMPTY
          ) {
            modalEmitter.emit('Reorder.Modal.Show', order);
            return;
          }
          modalEmitter.emit('Modal.Error.Open', responseError.getMessage());
        });
    },
    [inProcess],
  );

  useEffect(() => {
    orderEmitter.addListener('Order.Reorder', reorderHandler);
    orderEmitter.addListener('Order.Reorder.New', (order) =>
      reorderHandler(order, true),
    );
    orderEmitter.addListener('Order.Reorder.Add', (order) =>
      reorderHandler(order, false),
    );
    return () => {
      orderEmitter.removeListener('Order.Reorder', reorderHandler);
      orderEmitter.removeListener('Order.Reorder.New', (order) =>
        reorderHandler(order, true),
      );
      orderEmitter.removeListener('Order.Reorder.Add', (order) =>
        reorderHandler(order, false),
      );
    };
  }, [reorderHandler]);
};
