import { ExternalImage } from '@shared/ui/image';
import PlusMinusButton from 'frontend/Components/NewCartOrder/Components/PlusMinusButton/PlusMinusButton';
import { openCardModal } from 'frontend/Components/NewCartOrder/Components/CartProductsBlock/utils';
import TextBlock from 'frontend/Components/NewCartOrder/Components/CartProductsBlock/TextBlock/TextBlock';
import clsx from 'clsx';
import { Media } from '@shared/lib/media';
import { IProduct } from '../../../model/types';
import Tags from '../../tags/Tags';
import styles from './card.module.scss';

interface ICardProps {
  product: IProduct;
  price: number;
  currency: string;
  mainDomain: string;
}

function Card({ product, price, currency, mainDomain }: ICardProps) {
  const {
    images = [],
    tags = [],
    title = '',
    is_pickup,
    is_delivery,
    product_type,
    is_action,
    price_old,
  } = product;

  return (
    <div className={styles.root}>
      <button
        type="button"
        onClick={(event) => {
          openCardModal(event, { product, cartItemId: product.id });
        }}
        className={styles.cartItemButton}
      >
        <ExternalImage
          mainDomain={mainDomain}
          src={`${images[0]?.filename}/80x80`}
          alt={title}
          className={clsx(styles.cardImage, {
            [styles.longcard]: product_type?.name === 'longcard',
          })}
          width={80}
          height={80}
        />
        <Tags tags={tags} />
        <div className={styles.textContainer}>
          <TextBlock
            title={title}
            is_action={is_action}
            is_pickup={is_pickup}
            is_delivery={is_delivery}
            productType={product_type}
          />
          <div className={styles.priceContainer}>
            <p
              className={clsx(styles.price, {
                [styles.actionPrice]: is_action,
              })}
            >
              {price} {currency}
            </p>
            {is_action && +price_old !== +price && (
              <Media greaterThanOrEqual="laptop">
                <span className={styles.oldPrice}>
                  {price_old} {currency}
                </span>
              </Media>
            )}
          </div>
        </div>
      </button>
      <PlusMinusButton product={product} child={null} position={null} />
    </div>
  );
}

export default Card;
