import { Media } from '@shared/lib/media';
import loadable from '@loadable/component';
import { holidayCheck } from '@entities/holyday-decorations';
import { clsx } from 'clsx';
import { useCheckCountries } from '@entities/countries';
import type { IMenuElement } from '@entities/menu';
import type { IHeaderMenu } from '@entities/header-menu';
import { Desktop } from './desktop/Desktop';
import { Mobile } from './mobile/Mobile';
import styles from './header.module.scss';
import MobileNavigation from './mobile/mobileNavigation/MobileNavigation';
import { Logo } from './logo/Logo';
import { MobileSidebarOpener } from './mobile-sidebar-opener/MobileSidebarOpener';

const DecorationSpan = loadable(
  () =>
    import('@entities/holyday-decorations').then(
      ({ DecorationSpan: Decoration }) => Decoration,
    ),
  { ssr: false },
);

interface IHeaderProps {
  menuList: IMenuElement[];
  countryId: number;
  isCheckCountries: ReturnType<typeof useCheckCountries>;
  cityName: string;
  cityPhone: string;
  mainDomain: string;
  showSelectCity: () => void;
  headerMenu: IHeaderMenu[];
  cityAvailableLangs?: string[];
}

export function Header({
  menuList,
  countryId,
  isCheckCountries,
  cityName,
  cityPhone,
  mainDomain,
  showSelectCity,
  headerMenu,
  cityAvailableLangs,
}: IHeaderProps) {
  const holydayNameForLogo = holidayCheck({
    countryId,
    type: 'logo',
  });
  const logo = (
    <Logo
      holydayName={holydayNameForLogo}
      isCheckCountries={isCheckCountries}
    />
  );

  const mobileHeaderMenu = headerMenu.filter(({ mobile }) => mobile);
  const desktopHeaderMenu = headerMenu.filter(({ site }) => site);

  const mobileSidebarOpener = (
    <MobileSidebarOpener
      mainDomain={mainDomain}
      menuList={menuList}
      cityName={cityName}
      cityPhone={cityPhone}
      isCheckCountries={isCheckCountries}
      showSelectCity={showSelectCity}
      headerMenu={mobileHeaderMenu}
      cityAvailableLangs={cityAvailableLangs}
    />
  );
  const holydayNameForHeader = holidayCheck({
    countryId,
    type: 'header',
  });
  const decoration = {
    left: holydayNameForHeader && (
      <DecorationSpan position="left" holidayName={holydayNameForHeader} />
    ),
    right: holydayNameForHeader && (
      <DecorationSpan position="right" holidayName={holydayNameForHeader} />
    ),
  };
  return (
    <>
      <Media greaterThanOrEqual="notebook">
        {(className, renderChildren) =>
          renderChildren ? (
            <header
              id="main-header"
              className={clsx(className, styles.desktop)}
            >
              <Desktop
                showSelectCity={showSelectCity}
                decoration={decoration}
                logo={logo}
                cityPhone={cityPhone}
                headerMenu={desktopHeaderMenu}
                cityAvailableLangs={cityAvailableLangs}
              />
            </header>
          ) : null
        }
      </Media>
      <Media lessThan="notebook">
        {(className, renderChildren) =>
          renderChildren ? (
            <header id="main-header" className={clsx(className, styles.mobile)}>
              <Mobile
                mobileSidebarOpener={mobileSidebarOpener}
                decoration={decoration}
                logo={logo}
              />
              <MobileNavigation menuList={menuList} />
            </header>
          ) : null
        }
      </Media>
    </>
  );
}
