import { useEffect, useState } from 'react';

export const useIntervalIndex = (maxIndex: number, interval: number) => {
  const [index, setIndex] = useState<number | null>(null);

  const getRandomInt = (max: number) => Math.floor(Math.random() * max);

  useEffect(() => {
    const animateInterval = setInterval(() => {
      let newIndex = getRandomInt(maxIndex);
      // цикл while здесь для поиска не повторяющегося значения нового индекса с предыдущим
      while (newIndex === index) {
        newIndex = getRandomInt(maxIndex);
      }
      setIndex(newIndex);
    }, interval);

    return () => {
      clearInterval(animateInterval);
    };
  }, [index, interval, maxIndex]);

  return index;
};
