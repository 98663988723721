import { useTranslation } from 'react-i18next';
import { SmartLink } from '@shared/lib/routing';
import { Media } from '@shared/lib/media';
import { Fragment } from 'react';
import styles from './styles.module.scss';

export function Breadcrumbs({
  links,
}: {
  links: { text?: string; href?: string }[];
}) {
  const { t } = useTranslation();

  const homeLink = {
    text: t('localization.Main'),
    href: '/',
  };
  const list = (
    <ol
      itemScope
      itemType="http://schema.org/BreadcrumbList"
      className={styles.root}
    >
      {[homeLink, ...links].map(({ href, text = '' }, index, collection) => {
        const capitalizeText = text.charAt(0).toUpperCase() + text.substring(1);
        const isLast = collection.length - 1 === index;

        return (
          <Fragment key={text}>
            <li
              className={styles.item}
              itemProp="itemListElement"
              itemScope
              itemType="http://schema.org/ListItem"
            >
              {isLast ? (
                <span aria-current="location" itemProp="name">
                  {capitalizeText}
                </span>
              ) : (
                <SmartLink itemProp="item" href={href} className={styles.link}>
                  <span itemProp="name">{capitalizeText}</span>
                </SmartLink>
              )}
              <meta itemProp="position" content={String(index + 1)} />
            </li>
            {!isLast && (
              <span aria-hidden="true" className={styles.arrow}>
                {'>>'}
              </span>
            )}
          </Fragment>
        );
      })}
    </ol>
  );
  const isShownInMobile = links.length > 1;
  const mobileNav = (
    <Media greaterThanOrEqual="notebook">
      {(className, renderChildren) => (
        <nav aria-label="Breadcrumb" className={className}>
          {renderChildren ? list : null}
        </nav>
      )}
    </Media>
  );

  return isShownInMobile ? (
    <nav aria-label="Breadcrumb">{list}</nav>
  ) : (
    mobileNav
  );
}
