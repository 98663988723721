import { Media } from '@shared/lib/media';
import { clsx } from 'clsx';
import { useInView } from 'react-intersection-observer';
import styles from './styles.module.scss';
import { useDesktopScheme, useMobileScheme } from './scheme';
import { Caption } from './caption/caption';

type elementType = {
  name: string;
  link: string;
};

function HorizontalBlockDesktop({
  name,
  link,
}: {
  name: string;
  link: string;
}) {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: false,
    rootMargin: '1000px 0px 0px 0px',
  });
  const { findbyNameDesktop } = useDesktopScheme();
  const item = findbyNameDesktop(name);

  if (!item) return null;

  return (
    <div
      ref={ref}
      className={clsx(styles.animated, {
        [styles.zoomIn]: inView,
        [styles.zoomOut]: !inView,
      })}
    >
      <item.Container
        handleClick={item.handleClick}
        link={link}
        className={styles.container}
      >
        <item.Img
          width={450}
          height={450}
          src={item.imageSrc}
          alt={item.imageAlt}
          className={styles.imgWrapper}
        />
        <Caption title={item.title} description={item.description} />
      </item.Container>
    </div>
  );
}

function HorizontalBlockMobile({ name, link }: elementType) {
  const { findbyNameMobile } = useMobileScheme();
  const item = findbyNameMobile(name);
  if (!item) return null;
  return (
    <item.Container className={styles.container} link={link}>
      <item.Img
        width={450}
        height={450}
        src={item.imageSrc}
        alt={item.imageAlt}
        className={styles.imgWrapper}
      />
      <Caption title={item.title} description={item.description} />
    </item.Container>
  );
}

export function HorizontalBlocks({
  data,
  mobileData,
}: {
  data: elementType[];
  mobileData: elementType[];
}) {
  return (
    <>
      <Media className={clsx(styles.root)} greaterThanOrEqual="notebook">
        {data.map(({ name, link }) => (
          <HorizontalBlockDesktop key={name} name={name} link={link} />
        ))}
      </Media>
      <Media
        lessThan="notebook"
        className={clsx(styles.root, styles.mobileRoot)}
      >
        {mobileData.map(({ name, link }) => (
          <HorizontalBlockMobile key={name} name={name} link={link} />
        ))}
      </Media>
    </>
  );
}
