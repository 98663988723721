import { ISeoPage } from '@widgets/seoPage';
import type { FetchLoaderType } from '@shared/lib/routing';
import { menuIds } from './model/config';

type Params = {
  city: string;
  pageId: string;
};
export const argumentsPageLoader = async (
  fetch: FetchLoaderType,
  { params, lang }: { params: Params; lang: string },
) => {
  const { city } = params;
  const [seoPageResponse, ...productsByMenus] = await Promise.all([
    fetch<ISeoPage>({
      url: '/seo/page',
      params: { url: '/arguments', city, lang },
    }),
    ...menuIds.map((id) =>
      fetch<{ product: { price: number }[] }>({
        url: '/product/get_list',
        params: { menu: id, city, lang },
      }),
    ),
  ]);
  const minPricesByCategory = productsByMenus.map((productResponse) => {
    const prices = productResponse.data.product.map((item) => item.price);

    return prices.length ? Math.min(...prices) : 0;
  });
  return {
    minPricesByCategory,
    seoPageData: seoPageResponse.data,
  };
};
