import { useTranslation } from 'react-i18next';
import styles from './lables.module.scss';

export function Familiarization() {
  const { t } = useTranslation();
  return (
    <p className={styles.labelText}>
      {t('BlockTotal.By_making_an_order_for')}
      <a href="/">{t('BlockTotal.site')}</a>
      &nbsp;
      {t('BlockTotal.i_confirm')}&nbsp;
      <a href="/page/rules/">{t('BlockTotal.address')}</a>
      ,&nbsp;
      {t('BlockTotal.and_confirm')}
    </p>
  );
}

export function PersonalData() {
  const { t } = useTranslation();
  return (
    <p className={styles.labelText}>
      {t('BlockTotal.By_making_an_order_for')}
      <a href="/">{t('BlockTotal.site')}</a>
      &nbsp;
      {t('BlockTotal.iAgreeToTheCollection')}
      <a href="/page/terms/">{t('BlockTotal.privacy')}</a>.
    </p>
  );
}

export function Messages() {
  const { t } = useTranslation();
  return (
    <p className={styles.labelText}>
      {t('BlockTotal.By_making_an_order_for')}
      <a href="/">{t('BlockTotal.site')}</a>
      &nbsp;
      {t('BlockTotal.i_give_my_consent')}
    </p>
  );
}

export function AlergicProducts() {
  const { t } = useTranslation();
  return (
    <p className={styles.labelText}>
      {t('BlockTotal.By_making_an_order')}&nbsp;
      {t('BlockTotal.iConfirmBuy')}&nbsp;
      <a href="/akcii/polnye-usloviya-akcii-dr-sv-sekretnyj-tovar/?utm_source=allergen">
        {t('BlockTotal.alergicProducts')}
      </a>
      .
    </p>
  );
}
