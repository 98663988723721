import './AddModal.scss';
import autobind from 'autobind-decorator';
import { Component } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import queryString from 'query-string';
import { modalEmitter } from 'modules/event-emitters';
import { withTranslation } from 'react-i18next';
import { withBreakpoints } from 'frontend/hocs';
import { Button } from '@shared/ui/button';
import { clsx } from 'clsx';
import { withCheckCountries } from '@entities/countries';
import RadioButton from '@shared/ui/Radio/Radio';
import { InputTime } from '@shared/ui/inputs/inputTime';
import { InputOrder } from '@shared/ui/inputs/inputOrder';
import * as session from 'modules/session';
import paperClipImage from './assets/paper-clip.svg?url';
import Textarea from '../../Elements/Textarea/Textarea';
import ResponseError from '../../../../modules/response/error';
import Masked from '../../Masked';

const categories = ['service_delivery', 'service_store'];

const customStyles = {
  overlay: {
    zIndex: 20,
    backgroundColor: 'rgba(252, 252, 252, 0.7)',
    overflowY: 'auto',
  },
  content: {
    maxWidth: '680px',
    top: '5%',
    left: '0',
    right: '0',
    bottom: 'auto',
    margin: 'auto',
    overflow: 'visible',
    padding: '30px 60px 36px',
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 16px 60px 20px',
    boxSizing: 'border-box',
    marginBottom: '50px',
  },
};

const mobileStyles = {
  overlay: {
    zIndex: 20,
    backgroundColor: 'rgba(252, 252, 252, 0.7)',
    overflowY: 'auto',
  },
  content: {
    maxWidth: '680px',
    top: '',
    left: '0',
    right: '0',
    bottom: 'auto',
    margin: 'auto',
    overflow: 'visible',
    padding: '30px 30px 36px',
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 16px 60px 20px',
    boxSizing: 'border-box',
  },
};

const ERRORS = {
  FILE_TYPE: 415,
  IMAGE_SIZE: 413,
  ACCESS: 403,
};

class ReviewsAddModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      formSubmitted: false,
      checkedInfo: true,
      checkedAnswer: false,
      sended: false,
      store: null,
      city_stores: props.city_stores || [],
      invalidFields: {
        phone: true,
        comment: true,
      },
      form: {
        city: session.get('city'),
        store_id: 0,
        phone: '',
        file: '',
        comment: '',
        order_hash: '',
        need_answer: false,
        category: categories[0],
        reviews: {
          selects: [
            {
              name: 'quality',
              value: 4,
            },
            {
              name: 'service',
              value: 4,
            },
            {
              name: 'delivery',
              value: 4,
            },
            {
              name: 'content',
              value: 4,
            },
          ],
          times: [
            {
              name: 'delivery_time',
              value: '',
            },
          ],
        },
      },
      chosenFiles: [],
      fileErrorMessage: '',
      sendingFormData: false,
    };

    this.form = '';
  }

  componentDidMount() {
    Modal.setAppElement('body');
    modalEmitter.addListener('Review.Modal.Open', this.showAddFormModal);
    modalEmitter.addListener('Review.Modal.Close', this.closeAddFormModal);
    const { location: { search } = {} } = this.props;
    if (search) {
      const query = queryString.parse(search);
      const order = query.order || '';
      if (order.length > 0 || 'form' in query) {
        const { form } = this.state;
        form.order_hash = order;
        this.setState({
          form,
          modalIsOpen: true,
        });
      }
    }
  }

  componentWillReceiveProps({ city_stores }) {
    if (this.props.city_stores !== city_stores) {
      const newState = { city_stores };
      this.setState(newState);
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('hidden');
    modalEmitter.removeListener('Review.Modal.Open', this.showAddFormModal);
    modalEmitter.removeListener('Review.Modal.Open', this.closeAddFormModal);
  }

  @autobind
  handleChange(event) {
    const { city_stores } = this.props;
    let store = null;

    const { invalidFields } = this.state;

    if (event.target.name === 'comment') {
      if (event.target.value && event.target.value.length < 1000) {
        delete invalidFields.comment;
      } else {
        invalidFields.comment = true;
      }
    }
    if (event.target.name === 'order_hash') {
      if (
        event.target.value &&
        event.target.value.replace('_', '').length === 15
      ) {
        delete invalidFields.order_hash;
      } else {
        invalidFields.order_hash = true;
      }
    }

    const { form } = this.state;
    form[event.target.name] = event.target.value;
    if (event.target.name === 'store_id') {
      store = city_stores.find(({ id }) => +id === +event.target.value) || {};
    }

    this.setState((prevState) => ({
      form,
      store: store || prevState.store,
    }));
  }

  handleValidate = (value, name) => {
    const { invalidFields } = this.state;
    if (value) {
      delete invalidFields[name];
    } else {
      invalidFields[name] = true;
    }
    this.setState({
      invalidFields,
    });
  };

  @autobind
  handleSubmit(e) {
    e.preventDefault();
    const { sendingFormData } = this.state;
    if (sendingFormData) {
      return;
    }

    this.setState({
      sendingFormData: true,
    });
    const { invalidFields, form } = this.state;
    if (Object.keys(invalidFields).length > 0) {
      this.setState({
        formSubmitted: true,
        sendingFormData: false,
      });
    } else {
      const formData = new FormData(this.form);

      if (this.form.elements['image[]'].files.length === 0) {
        formData.delete('image[]');
      }

      if (form.category && form.category !== categories[1]) {
        formData.set('store_id', 0);
      } else if (form.category && form.category === categories[1]) {
        formData.delete('delivery');
      }
      formData.delete('info_personal');

      axios
        .post('/review/add', formData)
        .then(() => {
          this.setState({
            store: null,
            sended: true,
            form: {
              city: session.get('city'),
              store_id: 0,
              need_answer: false,
              phone: '',
              comment: '',
              category: categories[0],
            },
            sendingFormData: false,
            chosenFiles: [],
            invalidFields: {
              phone: true,
              comment: true,
            },
          });
          // eventBus.emitEvent('review_sended');
        })
        .catch((error) => {
          const responseError = new ResponseError(error);

          if (responseError.code === 500)
            this.setState({
              sendingFormData: false,
            });
          this.handleSubmitErrors(error);
        });
    }
  }

  @autobind
  handleSubmitErrors(error) {
    const { t } = this.props;
    const {
      response: {
        data: { code },
        status,
      },
    } = error;
    let errorMessage = '';
    switch (code) {
      case ERRORS.FILE_TYPE:
        errorMessage = t('AddModal.file_type_error');
        break;
      case ERRORS.IMAGE_SIZE:
        errorMessage = t('AddModal.file_size_error');
        break;
      case ERRORS.ACCESS:
        errorMessage = t('AddModal.file_access_error');
        break;
      default:
        errorMessage = t('AddModal.unknown_error');
        break;
    }
    if (status === ERRORS.IMAGE_SIZE) {
      errorMessage = t('AddModal.file_size_error');
    }

    this.setState({
      fileErrorMessage: errorMessage,
      errorCode: code || status,
    });
  }

  @autobind
  onChangeInputFile() {
    const { files } = this.fileInput;
    const chosenFiles = [];
    for (let i = 0; i < files.length; i += 1) {
      chosenFiles.push(files[i]);
    }
    this.setState({
      chosenFiles,
      fileErrorMessage: '',
      errorCode: '',
    });
  }

  getCommentLabel(comment) {
    if (!comment) {
      return 'quality_comment_title';
    }
    if (this.isInvalid('comment')) {
      return 'comment_is_overflow';
    }
    return 'Review';
  }

  renderRows = (t) => {
    const {
      form: {
        category,
        reviews: { times },
      },
    } = this.state;
    let {
      form: {
        reviews: { selects },
      },
    } = this.state;
    const storeSelect = category && category === categories[1];
    selects = storeSelect
      ? selects.filter((item) => item.name !== 'delivery')
      : selects;
    const selectItems = selects.map(({ name, value }, index) => (
      <tr key={`selects-${name}-${index}`}>
        <td className="quality-num-title">{t(`AddModal.${name}_title`)}</td>
        <td>
          {[1, 2, 3, 4, 5].map((num) => (
            <RadioButton
              key={num.toString()}
              checked={num === +value}
              name={name}
              id={`${name}-${num}`}
              onChange={this.changeRadio}
              value={num}
            >
              {num}
            </RadioButton>
          ))}
        </td>
      </tr>
    ));

    const timeItems = (
      <tr className="timeItems timeItems--form">
        {times.map(({ name }, index) => (
          <td className="timeItems__item" key={`times-${name}-${index}`}>
            {storeSelect ? (
              <div className="timeItems__title">
                {t('AddModal.receive_time_title')}
              </div>
            ) : (
              <div className="timeItems__title">
                {t(`AddModal.${name}_title`)}
              </div>
            )}
            <div className="timeItems__input-wrap">
              <InputTime
                placeholder="00:00"
                onInput={this.changeTime}
                name={name}
                autoComplete="off"
              />
            </div>
          </td>
        ))}
      </tr>
    );

    return (
      <tbody>
        {selectItems}
        {timeItems}
      </tbody>
    );
  };

  toggleAnswer = () => {
    const { checkedAnswer, form } = this.state;
    form.need_answer = !checkedAnswer;
    this.setState({ checkedAnswer: !checkedAnswer, form });
  };

  changeTime = ({ target }) => {
    const { form } = this.state;
    const { reviews } = form;
    const { name, value } = target;

    reviews.times.forEach((item) => {
      if (item.name === name) item.value = value;
    });
    this.setState({ form });
  };

  changeRadio = ({ target }) => {
    const { form } = this.state;
    const { reviews } = form;

    const { name, value } = target;

    reviews.selects.forEach((item) => {
      if (item.name === name) item.value = value;
    });
    this.setState({ form });
  };

  @autobind
  changeCategory({ target }) {
    const { form } = this.state;
    let { store } = this.state;
    const { city_stores = [] } = this.props;

    form.category = target.value;
    if (form.category !== 'service_store') {
      store = {};
    } else {
      store = city_stores.find(({ id }) => +id === +form.store_id) || {};
    }

    this.setState({
      form,
      store,
    });
  }

  @autobind
  closeAddFormModal() {
    const tab = document.querySelector('.reviews-tabs__item');
    const btn = document.querySelector('.reviews-add__show-modal');

    this.setState((prevState) => ({
      modalIsOpen: false,
      formSubmitted: false,
      sended: false,
      form: {
        ...prevState.form,
        phone: '',
        comment: '',
        category: '',
        need_answer: false,
        reviews: {
          selects: [
            {
              name: 'quality',
              value: 4,
            },
            {
              name: 'service',
              value: 4,
            },
            {
              name: 'delivery',
              value: 4,
            },
            {
              name: 'content',
              value: 4,
            },
          ],
          times: [
            {
              name: 'delivery_time',
              value: '',
            },
          ],
        },
      },
    }));

    if (tab && btn) {
      tab.classList.add('reviews-tabs__item--active');
      btn.classList.remove('reviews-tabs__item--active');
    }
  }

  @autobind
  showAddFormModal(store) {
    const { form } = this.state;
    form.store_id = (store && store.id) || 0;
    if (store && store.id) {
      form.category = 'service_store';
    }

    this.setState({
      store,
      modalIsOpen: true,
      form,
    });
  }

  isInvalid(name) {
    const { formSubmitted, invalidFields } = this.state;
    return formSubmitted && invalidFields[name];
  }

  @autobind
  renderStoreHidden() {
    const { form } = this.state;

    return (
      <div>
        <input type="hidden" name="store_id" value={form.store_id} />
      </div>
    );
  }

  @autobind
  renderStoreSelect() {
    const { city_stores, form } = this.state;
    const { t } = this.props;
    return (
      <div className="form__choice-group">
        <div className="form__select-group">
          <div className="input-container input-container--select">
            <label htmlFor="store_id">{t('AddModal.store')}</label>
            <select
              name="store_id"
              id="store_id"
              className="form__select"
              value={form.store_id}
              onChange={this.handleChange}
            >
              <option value="0">{t('AddModal.selectStore')}</option>
              {city_stores.map((store, index) => (
                <option key={index} value={store.id}>
                  {store.address || store.store_name_site}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }

  @autobind
  renderSended() {
    const { t } = this.props;
    return (
      <div className="form__success">
        <div className="form__icon form__icon--success">
          {t('AddModal.data_sent')}!
        </div>
        <div className="form__subtitle form__subtitle--success">
          {t('AddModal.thanks')}!
        </div>
        <p className="form__text form__text--success">
          {t('AddModal.sent_to_moderation')}
        </p>
      </div>
    );
  }

  @autobind
  renderModal() {
    const {
      form,
      chosenFiles,
      fileErrorMessage,
      sendingFormData,
      errorCode,
      checkedInfo,
      checkedAnswer,
    } = this.state;
    const { t, isUkraine } = this.props;
    const storeSelect = form.category && form.category === categories[1];
    const fileLabel =
      chosenFiles.length > 0
        ? t('AddModal.Chosen_images')
        : t('AddModal.Add_image');
    return (
      <div className="input-group--100">
        <form
          ref={(form) => {
            this.form = form;
          }}
          className="form"
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <h3 className="form__title">
            {t('AddModal.evaluationOfTheQualityOfWork')}
          </h3>
          <div className="form__title--sub">{t('AddModal.subTitle')}</div>
          <div className="input-container input-container--select">
            <label htmlFor="category">{t('AddModal.review_subject')}</label>
            <select
              className="form__select"
              id="category"
              name="category"
              value={this.state.form.category}
              onChange={this.changeCategory}
            >
              {categories.map((category, index) => (
                <option key={`category${index}`} value={category}>
                  {t(`AddModal.${category}`)}
                </option>
              ))}
            </select>
          </div>
          {(storeSelect && this.renderStoreSelect()) ||
            this.renderStoreHidden()}

          <div className="form-review__input-wrap">
            <div
              className={`input-container ${
                this.isInvalid('order_hash') ? 'error' : undefined
              }`}
            >
              {this.isInvalid('order_hash') ? (
                <label className="form__label">
                  {t('AddModal.orderNumberIsNotCorrect')}
                </label>
              ) : (
                <label className="form__label">
                  {t('AddModal.quality_order_id_title')}
                </label>
              )}
              <InputOrder
                mask="111-111-111-111"
                type="tel"
                placeholder="###-###-###-###"
                patterns={{ '#': /[0-9]/ }}
                onInput={this.handleChange}
                value={this.state.form.order_hash}
                name="order_hash"
              />
            </div>

            <div
              className={`input-container ${
                this.isInvalid('phone') ? 'error' : undefined
              }`}
            >
              {this.isInvalid('phone') ? (
                <label className="form__label form__label--required">
                  {t('AddModal.invalid_phone')}
                </label>
              ) : (
                <label className="form__label form__label--required">
                  {t('AddModal.phone')}
                </label>
              )}
              <Masked
                type="phone"
                classname="material"
                defaultValue={this.state.form.phone}
                name="phone"
                patterns={{ '#': /[0-9]/ }}
                onChange={this.handleChange}
                onValidate={(value) => this.handleValidate(value, 'phone')}
              />
            </div>
          </div>
          <table className="table">{this.renderRows(t)}</table>
          <div
            className={`input-container input-container--textarea form-quality-about ${
              this.isInvalid('comment') ? 'error' : undefined
            }`}
          >
            <label className="form__label">
              {t(`AddModal.${this.getCommentLabel(form.comment)}`)}
            </label>
            <Textarea
              name="comment"
              className="form__textarea"
              value={this.state.form.comment}
              onChange={this.handleChange}
              required
            />
          </div>
          {fileErrorMessage && errorCode === 400 && (
            <div className="input-container file error">
              <label className="form__label">{fileErrorMessage}</label>
            </div>
          )}
          <div
            className={`input-container file ${
              fileErrorMessage && errorCode !== 400 ? 'error' : ''
            }`}
          >
            <label className="form__label">
              {(fileErrorMessage && errorCode !== 400) || fileLabel}
            </label>
            <label className="form__custom_input">
              <input
                ref={(file) => (this.fileInput = file)}
                onChange={this.onChangeInputFile}
                multiple
                type="file"
                className="material"
                name="image[]"
              />
              <img
                itemScope
                itemType="https://schema.org/ImageObject"
                className="clip-image"
                src={paperClipImage}
                alt=""
              />
            </label>
            <div className="chosen-files-container">
              {chosenFiles.map((file, index) => (
                <div key={index} className="chosen-file-name">
                  {file.name}
                </div>
              ))}
            </div>
            <label className="form__hint">
              {errorCode === 413
                ? t('AddModal.file_size_hint')
                : t('AddModal.file_type_hint')}
            </label>
          </div>
          <div className="form-review__checkbox">
            <div
              className={clsx('checkbox-box__checkbox-container', {
                error: !checkedInfo,
                ua: isUkraine,
              })}
            >
              <input
                type="checkbox"
                id="info"
                name="info_personal"
                checked={checkedInfo}
                onChange={() => {
                  this.setState({ checkedInfo: !checkedInfo });
                }}
              />
              <label htmlFor="info"> {t('AddModal.info')}</label>
            </div>
          </div>
          <div className="form-review__checkbox">
            <div
              className={clsx('checkbox-box__checkbox-container', {
                ua: isUkraine,
              })}
            >
              <input
                type="checkbox"
                id="need_answer"
                name="need_answer"
                checked={checkedAnswer}
                onChange={this.toggleAnswer}
              />
              <label htmlFor="need_answer"> {t('AddModal.needAnswer')}</label>
            </div>
          </div>
          <div className="input-group add-modal__button">
            <Button
              className="sw-button"
              type="submit"
              disabled={sendingFormData || !checkedInfo ? 'disabled' : ''}
              onClick={this.handleSubmit}
            >
              {t('AddModal.addReview')}
            </Button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    const { isMobile } = this.props;
    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        onRequestClose={this.closeAddFormModal}
        style={isMobile ? mobileStyles : customStyles}
        contentLabel="Add form"
      >
        {this.state.sended ? this.renderSended() : this.renderModal()}
        <div
          className="close-modal-btn"
          data-action="close"
          onClick={this.closeAddFormModal}
        />
      </Modal>
    );
  }
}

export default withTranslation()(
  withBreakpoints(withCheckCountries(ReviewsAddModal)),
);
