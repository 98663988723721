import { useAppSelector } from '@shared/lib/store';
import {
  IDeliveryCheckParams,
  deliverySliceSelectors,
  useDeliveryCheckQuery,
} from '@entities/delivery';
import * as cartHelper from 'modules/helpers/cart-helper';
import { skipToken } from '@reduxjs/toolkit/query';
import { ICart } from 'types/cartTypes';
import globalStore from 'modules/global-store';
import { useMapDisabled } from '@shared/third-party-libs/commonMap';
import { useCityAndLang } from '@shared/lib/use-city-and-lang';

const getDeliveryCheckParams = ({
  cart,
  geoData,
  delivery_date,
  cityLang: { city, lang },
}: {
  cart: ICart;
  geoData: { latitude: string; longitude: string };
  delivery_date: string;
  cityLang: { city: string; lang: string };
}) => {
  const { total: order_sum, phone_bonuses, promo_code, bonus_phone } = cart;
  const product_ids = cartHelper.getActiveProductIds(cart);

  const deliveryCheckParams: IDeliveryCheckParams = {
    ...geoData,
    order_sum,
    product_ids,
    city,
    lang,
  };

  if (phone_bonuses.has_card) {
    deliveryCheckParams.enabled_discount = 1;
  }
  if (delivery_date.length) {
    deliveryCheckParams.delivery_date = delivery_date;
  }
  if (promo_code) {
    deliveryCheckParams.promo_code = promo_code;
  }
  if (bonus_phone) {
    deliveryCheckParams.bonus_phone = bonus_phone;
  }
  return deliveryCheckParams;
};

const useAddressDeliveryCheck = ({
  isPickup,
  selectedTime,
  selectedDate,
  cart,
  toTime,
}: {
  isPickup: boolean;
  selectedTime: string;
  selectedDate: string;
  cart: ICart;
  toTime?: boolean;
}) => {
  const { latitude, longitude } = globalStore.get('current_city');
  const isMapDisabled = useMapDisabled();

  const { fullAddress } = useAppSelector(
    deliverySliceSelectors.selectFullAddress,
  );

  const { deliveryData, deliveryError } = useAppSelector(
    deliverySliceSelectors.selectDeliveryState,
  );

  const additionalParams = useCityAndLang();

  // const isDeliveryCheckReady =
  //   !isPickup && (fullAddress || isMapDisabled) && (!toTime || selectedTime);

  let deliveryCheckParams: IDeliveryCheckParams | typeof skipToken = skipToken;

  if (
    isPickup ||
    (!fullAddress && !isMapDisabled) ||
    (toTime && !selectedTime)
  ) {
    deliveryCheckParams = skipToken;
  } else if (isMapDisabled) {
    deliveryCheckParams = getDeliveryCheckParams({
      cart,
      geoData: { latitude, longitude },
      delivery_date: toTime ? `${selectedDate} ${selectedTime}` : '',
      cityLang: additionalParams,
    });
  } else if (fullAddress) {
    deliveryCheckParams = getDeliveryCheckParams({
      cart,
      geoData: {
        latitude: fullAddress.latitude,
        longitude: fullAddress.longitude,
      },
      delivery_date: toTime ? `${selectedDate} ${selectedTime}` : '',
      cityLang: additionalParams,
    });
  }

  // const deliveryCheckParams = isDeliveryCheckReady
  //   ? getDeliveryCheckParams({
  //       cart,
  //       geoData: isMapDisabled
  //         ? { latitude, longitude }
  //         : {
  //             latitude: fullAddress.latitude,
  //             longitude: fullAddress.longitude,
  //           },
  //       delivery_date: toTime ? `${selectedDate} ${selectedTime}` : '',
  //     })
  //   : skipToken;
  useDeliveryCheckQuery(deliveryCheckParams);

  return { fullAddress, deliveryData, deliveryError };
};

export default useAddressDeliveryCheck;
