import { Preloader } from '@shared/ui/preloader';
import { useTranslation } from 'react-i18next';
import { useReorderHandler } from 'frontend/Components/Profile/model/useReorderHandler';
import { BlockContainer } from '@shared/ui/content-containers';
import { Subtitle } from '@shared/ui/titles';
import { useSelectCountryMainDomain } from '@entities/countries';
import useUserIsExist from 'frontend/data/user/useUserExist';
import { OrderStatus } from '@entities/user/model/types';
import {
  ActiveOrder,
  getProductsIds,
  OrderList,
  useGetUserOrdersQuery,
} from '@entities/user';
import { ReorderModal } from 'frontend/Components/Modals/ReorderModal';
import { useCityAndLang } from '@shared/lib/use-city-and-lang';
import { getProductMenuUrl, useGetProductQuery } from '@entities/product';
import { useSelectMenu } from '@entities/menu';
import styles from './orders.module.scss';

function Orders() {
  const { t, i18n } = useTranslation();
  const mainDomain = useSelectCountryMainDomain();
  const { city, lang } = useCityAndLang();
  const { isUserExist } = useUserIsExist();
  const menu = useSelectMenu();
  useReorderHandler();

  const { data, isFetching, isUninitialized } = useGetUserOrdersQuery(
    {
      lang: i18n.language,
    },
    { skip: !isUserExist },
  );

  const orders = data?.data?.orders;
  const isProcessing = isFetching || isUninitialized;

  const activeOrder = orders?.find(
    (order) => order.status_id < OrderStatus.Delivered,
  );
  const items = activeOrder?.items || [];
  const productsIds = getProductsIds(items);

  const {
    data: productsData,
    isFetching: isProductFetching,
    isUninitialized: isProductUninitialized,
  } = useGetProductQuery(
    { city, lang, id: productsIds },
    { skip: isProcessing },
  );

  const activeOrderProducts = productsData?.data?.product || [];

  const itemsWithUrl = items.map((item) => {
    const product = activeOrderProducts.find(
      (itemProduct) => itemProduct.id === item.product_id,
    );
    const productMenu = menu.find(
      (itemMenu) => itemMenu.id === product?.menu_id,
    );
    const productUrl = getProductMenuUrl(product, productMenu?.text_id);

    return {
      ...item,
      productUrl,
    };
  });

  if (isProductFetching || isProductUninitialized) return <Preloader />;

  return (
    <>
      {activeOrder && (
        <div className={styles.active}>
          <p className={styles.activeTitle}>{t('OrderList.active_order')}</p>
          <ActiveOrder
            order={activeOrder}
            items={itemsWithUrl}
            mainDomain={mainDomain}
          />
        </div>
      )}
      <BlockContainer childrenPosition="start">
        <Subtitle>
          {t('Orders.my_orders')} {orders?.length ? `(${orders.length})` : ''}
        </Subtitle>
        <OrderList orders={orders} />
      </BlockContainer>
      <ReorderModal />
    </>
  );
}

export default Orders;
